import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from './theme';


import backgroundMobile from "./assets/images/gluinz-background-mobile.jpg";

import Navbar from './components/navbar/Navbar';

import Intro from "./components/intro/Intro";
import VideoGrid from "./components/videosections/small/VideoGrid";
import LargeVideoGrid from "./components/videosections/large/LargeVideoGrid";
import Pdf from "./components/pdf/Pdf";
// import Form from "./components/form/Form";
import Team from "./components/team/Team";
import Footer from "./components/footer/Footer";


function App() {

  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <div className="App" 
      style=
      {{ 
        backgroundImage: `url(${ matchesSm ? backgroundMobile : undefined})`, 
        height:matchesSm ? "100%" : undefined,
        backgroundSize:matchesSm ? "cover" : undefined, 
        backgroundPosition: matchesSm ? "absolute" : undefined, 
        backgroundRepeat:matchesSm ?  "no-repeat" : undefined}}
      >
         
          <Navbar />
            <Intro intro={true} />
            <LargeVideoGrid />
            <Intro intro={false}/>
            <VideoGrid />
            {/* <Pdf /> */}
            {/* <Form /> */}
            <Footer />
            <Team />
      </div>
    </ThemeProvider>
  );
}

export default App;

