import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import banner from '../../../assets/images/banner2.svg';

import GridWrapper from '../../gridwrapper/GridWrapper';
function LargeVideoGrid() {

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container mt={matchesSm ? undefined : 12} style={{backgroundImage: `url(${matchesSm ? undefined : banner})`, backgroundSize: "cover", backgroundPosition: "absolute", backgroundRepeat: "no-repeat"}}>
    <GridWrapper>
    <Grid item container lg={12} mb={matchesSm ? undefined : 10} display="flex" justifyContent="center" ml={matchesSm ? 4 : 8} mr={matchesSm ? 4 : 8} style={{height:matchesSm ? '300px' : '640px'}}>  
                    <iframe src="https://player.vimeo.com/video/679899193?h=86a385baa7&byline=0&portrait=0" style={{width:'100%',height:'100%'}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen />             
        </Grid>
        {/* <Grid item container lg={12} display="flex" ml={matchesSm ? 2 : 8} mr={matchesSm ? 2 : 8} mb={6}>
            <Box>
                <Typography variant="main" color="primary" fontSize='24px' gutterBottom>
                    Title
                </Typography>
                <br />
                <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                Todhchaí na Gaeltachta,
                </Typography>
            </Box>   
        </Grid> */}
        </GridWrapper>
    </Grid>
  );
}

export default LargeVideoGrid;
