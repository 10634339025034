import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography:{
      fontFamily: 'Circular Std',
      fontWeightLight: 450,
      fontWeightRegular: 500,
      fontWeightMedium: 700,
      fontWeightBold: 900,
      main:{
        fontFamily: 'Circular Std',
        fontSize: "20px",
        fontWeight: 700
      },
      header:{
        fontFamily: 'Circular Std',
        fontSize: "36px",
        fontWeight: 700
      },
      navlink:{
        fontFamily: 'Circular Std',
        fontSize: "24px",
        fontWeight: 450
      },
    },
    palette:{
      primary:{
        main: '#EB9323'
      },
      secondary:{
        main: '#382157'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        imac: 2200
      },
    },
  });

  export default theme;