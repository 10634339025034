import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import GridWrapper from "../gridwrapper/GridWrapper";

import { AiOutlineMail } from "react-icons/ai";

import { BsHash } from "react-icons/bs";

function Footer() {

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container mt={2}>
        <GridWrapper>
            <Grid container mr={10} ml={6}>
                <Grid item lg={12} sm={12} mt={4} ml={matchesSm ? 4 : undefined} display="flex" justifyContent="center" style={{width:"100%"}}>
                <Typography variant="header" color="white" gutterBottom style={{textAlign:'center'}} fontSize={matchesSm ? "30px" : undefined}>
                    Déan Teagmháil Linn
                </Typography>
                </Grid>
                <Grid item lg={6} sm={12} p={5} display="flex" justifyContent="center" style={{width:"100%"}}>
                <Avatar sx={{ color: 'white',bgcolor: '#4B2C75', marginTop:'-8px', marginRight:"8px" }}><BsHash/></Avatar>
                <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize={'18px'}>
                    #gluinz
                </Typography>
                </Grid>
                <Grid item lg={6} sm={12} p={5} display="flex" justifyContent="center" style={{width:"100%"}}>
                <Avatar sx={{ color: 'white',bgcolor: '#4B2C75', marginTop:'-8px', marginRight:"8px"}}><AiOutlineMail/></Avatar>
                <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize={'18px'}>
                    gluinz@udaras.ie
                </Typography>
                </Grid>
            </Grid>
        </GridWrapper>
    </Grid>
  );
}

export default Footer;