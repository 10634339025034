import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import logo from '../../assets/images/gluinzwhite.svg';
import udaras from '../../assets/images/Udaras2.svg';

import GridWrapper from '../gridwrapper/GridWrapper';

function Navbar() {

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('xl'));
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <GridWrapper>
    <Box sx={{ flexGrow: 1 }} pl={0} pr={0}>
      <AppBar position="static" elevation={0} style={{background: '#382157', padding: matchesSm ? undefined : "30px"}}>
        <Toolbar>
          <Grid container>
            <Grid item lg={1} sm={1} xs={2}>
              <img src={logo} alt="Gluin Z Logo" style={{width:matchesLg ? "55px" : "100px"}} />
            </Grid>
            <Grid item lg={8} sm={8} xs={6}>
            <Box mt={matchesLg ? 0 : 2} ml={matchesLg ? 1 : 2}>
              <Typography variant="header" component="div" color="white" fontSize={matchesSm ? "22px" : undefined}>
                Glúin Z
              </Typography>
              <Typography variant="main" component="div" color="white" fontWeight="fontWeightLight" fontSize={matchesSm ? "12px" : matchesMd ? "18px" : undefined}>
                Ag Cumasú Ghlúin {matchesSm && <br/>}Óg na Gaeltachta
              </Typography>
            </Box>
            </Grid>
            <Grid item lg={3} sm={3} xs={4} display="flex" justifyContent="flex-end">
            <img src={udaras} alt="udaras logo" style={{width:matchesLg ? "120px" : "220px"}} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
    </GridWrapper>
  );
}

export default Navbar;
