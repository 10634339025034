import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

//mui@styles not compatible with strictmode or react 18

ReactDOM.createRoot(
  document.getElementById('root')
).render(<App />);


