import Grid from '@mui/material/Grid';
import GridWrapper from "../gridwrapper/GridWrapper";

import banner from '../../assets/images/test.svg';
import udaras from '../../assets/images/Udaras.svg';
import gaeltacht from '../../assets/images/gaeltacht1.png';
import ghaeilge from '../../assets/images/Straiteis20.svg';
import rialtas from '../../assets/images/rialtas.svg';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Team() {

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container  mt={6} pb={6} style={{backgroundImage: `url(${banner})`, backgroundSize: "cover", backgroundPosition: "absolute", backgroundRepeat: "no-repeat"}}>
        <GridWrapper>
            <Grid container mr={10} ml={10} >
                <Grid item lg={3} md={6} xs={12} mt={matchesSm ? 6 : 10} display="flex"justifyContent="center">
                    <img src={udaras} style={{maxHeight: matchesSm ? '100%': '75%', maxWidth:matchesSm ? '100%': '75%' }} alt="udaras" />
                </Grid>
                <Grid item lg={3} md={6} xs={12} mt={matchesSm ? 2 : 10} display="flex"justifyContent="center">
                    <img src={gaeltacht} style={{maxHeight: '75%', maxWidth: '80%' }} alt="gaeltacht" />
                </Grid>
                <Grid item lg={3} md={6} xs={12} display="flex"justifyContent="center">
                    <img src={rialtas} style={{maxHeight: '100%', maxWidth: '100%' }} alt="rialtas" />
                </Grid>
                <Grid item lg={3} md={6} xs={12} display="flex"justifyContent="center">
                    <img src={ghaeilge} style={{maxHeight: '100%', maxWidth: '100%' }} alt="ghaeilge" />
                </Grid>
            </Grid>
        </GridWrapper>
    </Grid>
  );
}

export default Team;