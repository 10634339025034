
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function GridWrapper(props) {

  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Grid container>
      <Grid container mr={matchesLg ? undefined : 10} ml={matchesLg ? undefined : 10} mt={matchesLg ? 4 : 10} >
      {props.children}
      </Grid>
    </Grid>
  );
}

export default GridWrapper;