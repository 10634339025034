import { useState } from 'react';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';


import banner from '../../../assets/images/banner1.svg';
import main from '../../../assets/images/main.png';
import seven from '../../../assets/images/one.png';
import four from '../../../assets/images/two.png';
import five from '../../../assets/images/three.png';
import two from '../../../assets/images/four.png';
import one from '../../../assets/images/five.png';
import six from '../../../assets/images/six.png';
import nine from '../../../assets/images/seven.png';
import eight from '../../../assets/images/eight.png';
import three from '../../../assets/images/nine.png';

import Fade from '@mui/material/Fade';



import Modal from '@mui/material/Modal';
import {AiFillPlayCircle, AiFillCloseCircle} from 'react-icons/ai';
import GridWrapper from '../../gridwrapper/GridWrapper';

function VideoGrid() {

  const [popup, setPopup] = useState(false);
  const [video, setVideo] = useState(0);

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

  function handlePopupOpen(value){
      setVideo(value);
      setPopup(true);
      
  }
  function handlePopupClose(){
    setPopup(false);
}

  return (

    <Grid container style={{backgroundImage: `url(${matchesSm ? undefined : banner})`, backgroundSize: "cover", backgroundPosition: "absolute", backgroundRepeat: "no-repeat"}} mt={6} >
        <GridWrapper>
                <Modal
                    open={popup}
                    onClose={handlePopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Fade in={popup}>
                    <Box sx={{position: 
                    'absolute',
                    borderRadius:"10px",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width:'75%',
                    height: matchesLg ? '40%' : '70%',
                    outline:'none'
                    }}>
                        <Box display="flex" justifyContent="flex-end" >
                          <AiFillCloseCircle  style={{cursor: 'pointer'}}  onClick={handlePopupClose} color="white" size={33} />
                          </Box>
                         {video === 1 
                         
                         && <iframe src="https://player.vimeo.com/video/701162884?h=99f5121446&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"100%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 2
                         && <iframe src="https://player.vimeo.com/video/696913941?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 3 
                         && <iframe src="https://player.vimeo.com/video/696920875?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 4
                         && <iframe src="https://player.vimeo.com/video/696910648?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 5
                         && <iframe src="https://player.vimeo.com/video/696912050?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 6 
                         && <iframe src="https://player.vimeo.com/video/696916963?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 7
                         && <iframe src="https://player.vimeo.com/video/696909797?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 8
                         && <iframe src="https://player.vimeo.com/video/696919799?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
                          {video === 9 
                         && <iframe src="https://player.vimeo.com/video/696918991?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }
        	             {video === 10 
                         && <iframe src="https://player.vimeo.com/video/696916240?h=86a385baa7&autoplay=1&byline=0&portrait=0" style={{width:matchesXs ? "102%" : "100%",height:"92%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                         }

                    </Box>
                    </Fade>
                </Modal>

        <Grid item container lg={12}>
            
        <Grid item lg={4} xs={12} md={6} p={1} pl={4} pr={matchesLg ? 3 : undefined}  spacing={12} display="flex" justifyContent="center">
            <Box style={{width:'100%'}}>
                <Box style={{width: '100%'}} >
                    <Card sx={{ width: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(1)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={main}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                        Comhdháil Ghlúin Z
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>

            <Grid item lg={4} xs={12} md={6} p={1} pl={matchesLg ? 4 : 2.5} pr={matchesLg ? 4 : 2.5} display="flex" justifyContent="center">
            <Box style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(2)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={two}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Óige Mhúscraí
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
            <Grid item lg={4} xs={12} md={6} p={1} pr={4} pl={matchesLg ? 4 : undefined} display="flex" justifyContent="center">
            <Box  style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(3)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={three}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Torthaí an Taighde
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
            <Grid item lg={4} xs={12} md={6} p={1} pl={4} pr={matchesLg ? 4 : undefined} display="flex" justifyContent="center">
            <Box  style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(4)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={four}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Finn & Erin
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
            <Grid item lg={4} xs={12} md={6} p={1} pl={matchesLg ? 4 : 2.5} pr={matchesLg ? 4 : 2.5} display="flex" justifyContent="center">
            <Box  style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(5)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={five}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Mar atá & Mar a bhí!
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
            <Grid item lg={4} xs={12} md={6} p={1} pr={4} pl={matchesLg ? 4 : undefined} display="flex" justifyContent="center">
            <Box style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(6)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={six}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Lís Ní Dhálaigh, TG4
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
            <Grid item lg={4} xs={12} md={6} p={1} pl={4} pr={matchesLg ? 4 : undefined} display="flex" justifyContent="center">
            <Box  style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(7)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={seven}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Cuisle
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
            <Grid item lg={4} xs={12} md={6} p={1} pl={matchesLg ? 4 : 2.5} pr={matchesLg ? 4 : 2.5} display="flex" justifyContent="center">
            <Box  style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(8)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={eight}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Séamus Ó Fátharta, Kinia
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
            
            <Grid item lg={4} xs={12} md={6} p={1} pr={4} pl={matchesLg ? 4 : undefined} display="flex" justifyContent="center">
            <Box style={{width: '100%'}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(9)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={nine}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Róisín Nic Grianna, UISCE
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>

            {!matchesLg && <Grid item lg={4} xs={12} md={6} p={1} pl={4} pr={matchesLg ? 4 : undefined} display="flex" justifyContent="center">
            
            </Grid>}


            <Grid item lg={4} xs={12} md={6} p={1} pl={matchesLg ? 4 : 2.5} pr={matchesLg ? 4 : 2.5} display="flex" justifyContent="center">
            <Box  style={{width:"100%"}}>
                <Box style={{maxWidth: '100%'}} >
                    <Card sx={{ maxWidth: '100%' }} style={{cursor: 'pointer'}} onClick={() => handlePopupOpen(10)}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                            component="img"
                            height="100%"
                            image={one}
                            />
                            <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.54)',
                                color: 'white',
                                padding: '10px',
                            }}
                            display="flex"
                            justifyContent="center"
                            >
                            <Typography variant="h5"><AiFillPlayCircle size={40} /></Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box 
                    pt={2}
                  // pl={matchesSm ? undefined : 8}
                >
                    <Typography variant="main" color="primary" fontSize='20px' gutterBottom>
                    Kate & Niamh
                    </Typography>
                    <br />
                    {/* <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize='24px'> 
                    Todhchaí na Gaeltachta,
                    </Typography> */}
                </Box>
            </Box>
            </Grid>
        </Grid>
        </GridWrapper>
    </Grid>
  );
}

export default VideoGrid;