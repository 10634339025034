import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import GridWrapper from '../gridwrapper/GridWrapper';
import pdf from '../../assets/udaras.pdf';

function Intro({intro}) {

    const theme = useTheme();
    const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <GridWrapper>
    <Grid container mr={matchesXl ? 30 : 8} ml={matchesXl ? 30 : 9} mt={matchesXl ? undefined : 6}>
      {intro &&
      <>
        <Typography variant="header" color="primary" gutterBottom>
            GlúinZ
        </Typography>

        <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize={matchesSm ? '24px' : '30px'}>
        Coincheap agus cur chuige nua dírithe ar ghlúin óg na Gaeltachta.
        Iarracht chun bealaí nuálaíocha le teanga agus cultúr na Gaeltachta a chur chun cinn i measc na glúine óige agus deiseanna a chruthú in ábhar a bhfuil sainspéise faoi leith acu féin ann.
        </Typography>
        </>}
        {!intro &&
      <>
        <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize={matchesSm ? '24px' : '30px'} >
            Tá togra Ghlúin Z ag teacht as moltaí i dtaighde a choimisiúnaigh Údarás na Gaeltachta maidir le cultúr óige
            sa Ghaeltacht. Léargas ar thorthaí an taighde <a href={pdf}  style={{color:'white'}}>anseo.</a>
        </Typography>
        <Typography variant="main" color="white" fontWeight="fontWeightLight" fontSize={matchesSm ? '24px' : '30px'} mt={6}>
        <a href="https://player.vimeo.com/video/701162884" rel="noreferrer" target="_blank" style={{color:'white'}}>Comhdháil</a> <i>Glúin Z: Ag Cumasú Ghlúin Z na Gaeltachta</i> agus míreanna ón chomhdháil.
        </Typography>
        </>}
    </Grid>
    </GridWrapper>
  );
}

export default Intro;